<template>
  <b-modal
    id="approveModal"
    centered
    hide-footer
    :title="title"
    ref="approveModal"
  >
    <b-card-text class="text-center">
      <strong>
        {{ textContent }}
      </strong>
    </b-card-text>
    <hr />
    <b-row>
      <b-col v-if="typeof showInput != 'undefined'" cols="12">
        <b-form-group
          id="comment-group"
          :label="$t('comment') + ':'"
          label-for="comment"
        >
          <b-form-textarea id="comment" v-model.trim="showInput" required>
          </b-form-textarea>
        </b-form-group>
      </b-col>
      <b-col class="text-center">
        <b-button
        size="sm"
          variant="danger"
          @click="$bvModal.hide('approveModal')"
          class="mr-2"
        >
          <feather-icon icon="XIcon"></feather-icon>
        </b-button>
        <b-button variant="primary" size="sm" @click="approve()">
          <feather-icon icon="CheckIcon"></feather-icon>
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

import Ripple from "vue-ripple-directive";
export default {
  name: "ApproveModal",

  directives: {
    Ripple,
  },
  props: ["textContent", "title", "showInput"],
  methods: {
    approve() {
      this.$parent.showInput = this.showInput;
      this.$parent.approve = true;
      this.$parent.callApproveModalAction();
      this.$refs["approveModal"].hide();
    },
  },
};
</script>

<style scoped></style>
